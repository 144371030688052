.element {
  .ant-card-body {
    padding: 0;
  }
  .element-preview {
    // border-bottom: 2px solid #13ec90;
    background: #fff;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    height: 210px;
    overflow: scroll;
    iframe {
      border: none;
      width: 100%;
      min-height: 200px;
    }
  }
  .preview-label {
    margin: 0;
    background: linear-gradient(-90deg, #0e6725, #08a765);
    color: white;
    text-align: center;
    padding: 5px;
    font-weight: bold;
  }
  .element-content {
    background: #f6fffb;
    padding: 24px;
    .element-details {
      height: 375px;
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
          margin: 10px 0;
        }
      }
    }
    .element-actions {
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        li {
          flex: 1;
          text-align: center;
        }
      }
    }
  }
}
