.autoresponder-modal {
  .ant-modal-body {
    max-height: 500px;
    overflow: scroll;
    .autoresponder-service {
      margin: 15px 0 0 0;
      font-weight: bold;
      text-align: center;
      padding-bottom: 15px;
      border-bottom: 1px solid #e8e8e8;
      .autoresponder-img {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        img {
          max-width: 50px;
          max-height: 50px;
        }
      }
    }
    .autoresponder-service:hover {
      background:#d1e8d1;
    }
  }
}