.links {
  .ant-card-body {
    padding: 24px !important;
  }
  .element-details {
    height: 170px;
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
  }
  .element-actions {
    margin-top: 30px;
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        li {
            flex: 1;
            text-align: center;
        }
    }
  }
}