.bonus-page {
  table {
    width: 100%;
    td {
      vertical-align: top;
      padding: 8px 0;
    }
    th:nth-child(2), td:nth-child(2) {
      width: calc(100% - 400px);
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}