.circle-ripple {
  background-color: #35ffc3;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  display: inline-block;
  vertical-align: top;
  animation: live_preview_pulse 0.7s linear infinite;
}

@keyframes live_preview_pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(#65ff78, 0.3),
                0 0 0 5px rgba(#65ff78, 0.3),
                0 0 0 10px rgba(#65ff78, 0.3),
  }
  100% {
    box-shadow: 0 0 0 5px rgba(#65ff78, 0.3),
                0 0 0 10px rgba(#65ff78, 0.3),
                0 0 0 15px rgba(#65ff78, 0.0),
  }
}
.preview-sidebar {
  width: 35%;
  height: 100%;
  position: fixed;
  top: 64px;
  left: 0;
  background: rgba(2,4,41,0.95);
  z-index: 1000;
  transform: translateX(-100%);
  transition: transform 0.45s;
  .live-preview {
    position: absolute;
    bottom: 0;
    width: 90%;
    display: block;
    margin: 0 auto 85px auto;
    iframe {
      width: 100%;
      border: none;
      background: white;
      height: 0;
    }
    svg {
      margin: auto;
      display: inline-block;
    }
  }
}
.preview-sidebar.visible {
  transform: translateX(0);
}
.element-form-container {
  width: 65%;
  height: calc(100% - 64px);
  padding: 30px 0;
  position: fixed;
  top: 64px;
  right: 0;
  z-index: 1000;
  background: white;
  transform: translateX(100%);
  transition: transform 0.45s 0.3s;
  p.form-labels {
    font-weight: 500;
  }
  .indented {
    padding-left: 30px;
  }
  .btn-green {
    margin-right: 20px
  }
  .form-container {
    overflow: scroll;
    padding: 0 45px;
  }
  .ant-card {
    opacity: 0;
    transition: opacity 0.3s;
  }
  .ant-radio-button-wrapper {
    text-align: center;
    height: auto;
    padding: 10px 15px 0 15px;
    vertical-align: middle;
    p {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .ant-radio-button-wrapper:hover {
    color: #36C626;
  }
  .ant-radio-button-wrapper-checked {
    border-color: #36C626;
    box-shadow: -1px 0 0 0 #36c625;
  }
  .template-select {
    width: 300px !important;
    .ant-select-selection--single {
      display: flex;
      height: auto;
    }
  }
  .ant-btn-danger {
    background: #f5222d;
    color: white;
  }
  .ant-card {
    height: 100%;
    .ant-card-body {
      height: 100%;
      .ant-tabs {
        height: 100%;
        .ant-tabs-tab {
          border-right: 1px solid #BFBFBF;
        }
        .ant-tabs-tab-active {
          border-right: 6px solid #020429;
        }
        .ant-tabs-content {
          height: 100%;
          padding: 0;
          .tab-body > div{
            position: relative;
            height: 100%;
            overflow: scroll;
            scroll-behavior: smooth;
            padding: 0 60px;
          }
          .tab-header {
            position: relative;
            padding-bottom: 15px;
            padding-left: 13px;
            border-bottom: 1px solid #979797;
            position: sticky;
            padding-top: 30px;
            top: 0;
            background: white;
            z-index: 999;
            h3 {
              font-size: 20px;
              line-height: 22px;
              font-weight: 400;
              display: inline-block;
              margin: 0;
              padding-top: 5px;
            }
            .right {
              position: sticky;
              left: 100%;
            }
          }
          .tab-body {
            height: 100%;
            padding-top: 0;

            .ant-form-item-control {
              margin: 16px 0;
              p.form-labels {
                margin-bottom: 10px;
                font-size: 16px;
                line-height: 22px;
                color: #464646;
                .switch-green {
                  margin-left: 16px;
                  margin-top: -3px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.element-form-container.visible {
  transform: translateX(0);
  transition: transform 0.45s;
  padding: 30px 0;
  .ant-card {
    transition: opacity 0.3s 0.35s;
    opacity: 1;
  }
}
